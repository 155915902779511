import { CircularProgress, Grid } from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import { useQuery } from "react-query"

const DailyPayments = ({ isRefetch = false }) => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const [dailyPayments, setDailyPayments] = useState([])
	const [colors, setColors] = useState([
		{
			borderColor: "border-green-700",
			progressColor: "success"
		},
		{
			borderColor: "border-base-color-light",
			progressColor: "secondary"
		},
		{
			borderColor: "border-blue-500",
			progressColor: "info"
		},
		{
			borderColor: "border-orange-400",
			progressColor: "warning"
		},
		{
			borderColor: "border-red-500",
			progressColor: "error"
		}
	])

	const { isLoading, isFetching, refetch } = useQuery({
		queryKey: "dailyPayments",
		queryFn: async function () {
			const response = await axiosPrivate.get("/dictionary/daily")
			return response.data.data
		},
		enabled: !hasError,
		onSuccess: (data) => {
			if (data && data.length > 0) {
				let total = 0
				let newDailyPayments = [
					{
						name: t("payment.dailyReceipts"),
						borderColor: "border-base-color",
						progressColor: "inherit"
					}
				]
				data.forEach((el, index) => {
					if (index !== 0) {
						newDailyPayments.push({
							name: el?.name,
							sum: el?.sum,
							borderColor: colors[index % 5].borderColor,
							progressColor: colors[index % 5].progressColor
						})
						total += el?.sum
					}
				})
				newDailyPayments[0].sum = total
				setDailyPayments([...newDailyPayments])
			} else {
				setDailyPayments([
					{
						name: t("payment.dailyReceipts"),
						borderColor: "border-base-color",
						progressColor: "inherit"
					}
				])
			}
		},
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	useEffect(() => {
		if (isRefetch) {
			refetch()
		}
	}, [isRefetch])

	return (
		<div className="mb-5">
			<Grid
				container
				spacing={2}
				columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
			>
				{dailyPayments.map((item, index) => (
					<Grid
						item={true}
						lg={2}
						md={4}
						sm={6}
						xs={12}
						key={`daily-payments-${index}`}
					>
						<div className="p-3 my-shadow-1 rounded-lg h-full flex items-center">
							<div
								className={`border-l-[5px] flex flex-col pl-2 ${
									item?.borderColor || "border-base-color"
								}`}
							>
								<span className="text-sm text-gray-600">{item?.name}</span>
								{isLoading || isFetching ? (
									<CircularProgress
										size={20}
										color={item?.progressColor || "inherit"}
										className="mt-1"
									/>
								) : (
									<NumericFormat
										value={item?.sum}
										allowNegative={false}
										displayType={"text"}
										thousandSeparator={" "}
										decimalScale={1}
										className="font-medium"
										suffix={" UZS"}
									/>
								)}
							</div>
						</div>
					</Grid>
				))}
			</Grid>
		</div>
	)
}

export default DailyPayments
