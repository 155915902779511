import {
	Box,
	Button,
	Chip,
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	Menu,
	MenuItem,
	OutlinedInput,
	Select
} from "@mui/material"
import SimpleCheckbox from "components/ui/simple-fields/checkbox/SimpleCheckbox"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import { useFormik } from "formik"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useTopPanel from "hooks/useTopPanel"
import { Fragment, useEffect, useMemo, useRef, useState } from "react"
import { useQueries } from "react-query"
import { useSearchParams } from "react-router-dom"
import * as yup from "yup"
import FormNumberField from "components/ui/form/FormNumberField"
import useNotification from "hooks/useNotification"
import FormRadioGroupField from "components/ui/form/FormRadioGroupField"
import FormTextField from "components/ui/form/FormTextField"
import useFormSubmit from "hooks/useFormSubmit"
import ShaxmatkaBlocksTable from "./shaxmatka-parts/block/ShaxmatkaBlocksTable"
import ShaxmatkaModeSelect from "./shaxmatka-parts/ShaxmatkaModeSelect"
import Shaxmatka2BlockItem from "./shaxmatka-parts/block/Shaxmatka2BlockItem"
import ShaxmatkaBlockItem from "./shaxmatka-parts/block/ShaxmatkaBlockItem"
import ShaxmatkaFilter from "components/ui/shaxmatka-filters/ShaxmatkaFilter"
import ShaxmatkaHomeDetail from "./shaxmatka-parts/home/ShaxmatkaHomeDetail"
import {
	currencyTypeSelectOptions,
	repairTypeSelectOptions,
	residentTypeSelectOptions
} from "shared/selectOptionsList"
import FormLayoutField from "components/ui/form/FormLayoutField"
import FormSimpleSelectField from "components/ui/form/FormSimpleSelectField"
import useCurrency from "hooks/useCurrency"
import CurrencySubContent from "components/ui/text-formats/CurrencySubContent"
import { Trans, useTranslation } from "react-i18next"
import HOME_TYPE from "shared/homeTypeList"
import ShaxmatkaTopPanel from "./shaxmatka-parts/ShaxmatkaTopPanel"
import ShaxmatkaTypeMenu from "./shaxmatka-parts/ShaxmatkaTypeMenu"
import ShaxmatkaStatuses from "./shaxmatka-parts/ShaxmatkaStatuses"
import getShaxmatkaFieldDataByBlocks from "utils/getShaxmatkaFieldDataByBlocks"
import ShaxmatkaVisualView from "./shaxmatka-parts/ShaxmatkaVisualView"

const validationSchema = yup.object({
	number: yup.string().optional(),
	rooms: yup.string().required("shaxmatka.validation.rooms"),
	square: yup.number().required("shaxmatka.validation.square"),
	plan_id: yup.string().optional(),
	islive: yup.string().required("shaxmatka.validation.islive"),
	isrepaired: yup.string().required("shaxmatka.validation.isrepaired"),
	start: yup.number().required("shaxmatka.validation.start")
})

const priceEditValidationSchema = yup.object({
	isvalute: yup.string().optional(),
	repaired: yup.number().required("shaxmatka.validation.repaired"),
	norepaired: yup.number().required("shaxmatka.validation.norepaired"),
	deal_price: yup.number().required("shaxmatka.validation.dealPrice")
})

const ShaxmatkaBlock = () => {
	const { t, i18n } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const { setComponent } = useTopPanel()
	const [shaxmatkaMode, setShaxmatkaMode] = useState("VIEW")
	const [resetFilter, setResetFilter] = useState(false)
	const [filterExpanded, setFilterExpanded] = useState(false)
	const [homeExpanded, setHomeExpanded] = useState(false)
	const [blocks, setBlocks] = useState([])
	const [tabValue, setTabValue] = useState(0)
	const [selectedHomeBlockId, setSelectedHomeBlockId] = useState(1)
	const [editWithPlanActive, setEditWithPlanActive] = useState(false)
	const [editTypeMultiple, setEditTypeMultiple] = useState(false)
	const [selectedHomes, setSelectedHomes] = useState([])
	const sendNotification = useNotification()
	const { submit, isSubmitting } = useFormSubmit()
	const [isMultipleSubmitting, setIsMultipleSubmitting] = useState(false)
	const anchorSettingsRef = useRef(null)
	const [settingsMenuValue, setSettingsMenuValue] = useState("number")
	const [settingsMenu, setSettingsMenu] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()

	const formik = useFormik({
		initialValues: {
			number: "",
			rooms: "",
			square: "",
			islive: null,
			isrepaired: null,
			start: "",
			plan_id: ""
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			let newValues = {
				rooms: values.rooms,
				number: values.number,
				square: values.square,
				start: values.start,
				isrepaired: values.isrepaired,
				islive: values.islive,
				plan_id: values.plan_id
			}
			if (!editTypeMultiple && selectedHomes.length === 1) {
				submit(
					{ type: "put", contentType: "formData" },
					newValues,
					"/admin/home",
					"Xona",
					selectedHomes[0]?.id,
					false,
					handleResponse
				)
			} else if (editTypeMultiple) {
				setIsMultipleSubmitting(true)
				try {
					let multipleResponse = await Promise.all(
						selectedHomes.map((item) =>
							axiosPrivate.post(
								`/admin/home/update/${item?.id}`,
								parseToFormData(
									getMultipleHomeNewValuesByPlanActive(
										values,
										item?.id,
										item?.blockIndex
									)
								),
								{
									headers: { "Content-Type": "multipart/form-data" }
								}
							)
						)
					)
					handleMultipleResponse(multipleResponse)
				} catch (error) {
					sendNotification({
						msg: error?.response?.data?.message || error?.message,
						variant: "error"
					})
					setIsMultipleSubmitting(false)
				}
			}
		}
	})

	const [objectQuery, blocksQuery] = useQueries([
		{
			queryKey: "objectSingle",
			queryFn: async function () {
				const response = await axiosPrivate.get(
					`/admin/object/edit/${searchParams.get("objectId")}`
				)
				return response.data.data
			},
			enabled: false,
			retry: false
		},
		{
			queryKey: "objectBlocks",
			queryFn: async function () {
				const response = await axiosPrivate.get(
					`/admin/home/object/${searchParams.get("objectId")}${setBlocksById()}`
				)
				return response.data.data
			},
			onSuccess: (data) => {
				setBlocks(JSON.parse(JSON.stringify(data)))
			},
			enabled: false,
			retry: false
		}
	])

	const setBlocksById = () => {
		let entries = Object.fromEntries(searchParams)
		let objectsQuery = ""
		Object.keys(entries).forEach((item) => {
			if (item.includes("block")) {
				objectsQuery += `${item}=${entries[item]}&`
			}
		})
		return objectsQuery.length > 0 ? `?${objectsQuery}` : ""
	}

	const getMultipleHomeNewValuesByPlanActive = (values, homeId, blockIndex) => {
		let newValues = {
			rooms: values.rooms,
			square: values.square,
			start: values.start,
			isrepaired: values.isrepaired,
			islive: values.islive,
			_method: "put"
		}
		if (editWithPlanActive) {
			newValues.plan_id = values.plan_id
		} else {
			let home = blocksQuery.data[blockIndex]?.homes.filter(
				(item) => item.id === homeId
			)[0]
			newValues.plan_id = home.plan_id || ""
		}
		return newValues
	}

	const parseToFormData = (values) => {
		let formData = new FormData()
		for (let key in values) {
			formData.append(key, values[key])
		}
		return formData
	}

	const resetSelection = () => {
		Array.from(document.getElementsByClassName("home-item")).forEach((item) =>
			item.classList.remove("item-selected")
		)
	}

	const resetFilledHomeValues = (homeId, blockIndex) => {
		if (homeId) {
			blocks[blockIndex].homes.splice(
				blocks[blockIndex].homes.findIndex((item) => item.id === homeId),
				1,
				blocksQuery.data[blockIndex].homes.filter(
					(item) => item.id === homeId
				)[0]
			)
			setBlocks([...blocks])
		}
	}

	const checkRoomActive = (id, blockIndex) => {
		if (shaxmatkaMode === "VIEW") {
			return true
		}
		if (
			blocksQuery.data[blockIndex]?.homes.filter((item) => item.id === id)[0]
				.status !== HOME_TYPE.ACTIVE.code
		) {
			sendNotification({
				msg: t("shaxmatka.alerts.warning.invalidHome"),
				variant: "warning"
			})
			return false
		}
		return true
	}

	const toggleSelectionItem = (id, blockIndex) => {
		setSelectedHomeBlockId(blocksQuery.data[blockIndex]?.id)
		if (checkRoomActive(id, blockIndex)) {
			if (editTypeMultiple) {
				document
					.querySelector(`.block-${blockIndex}-home#home-${id}`)
					.classList.toggle("item-selected")
				if (selectedHomes.find((item) => item.id === id)) {
					selectedHomes.splice(selectedHomes.map((el) => el.id).indexOf(id), 1)
					resetFilledHomeValues(id, blockIndex)
				} else {
					if (selectedHomes.length === 0) {
						setMultipleHomeValuesToForm(id, blockIndex)
					} else {
						setFormDataToHome({ id: id, blockIndex: blockIndex })
						setBlocks([...blocks])
					}
					selectedHomes.push({ id: id, blockIndex: blockIndex })
				}
				setSelectedHomes(selectedHomes)
			} else {
				Array.from(document.getElementsByClassName("home-item")).forEach(
					(item) =>
						item.id !== `home-${id}` && item.classList.remove("item-selected")
				)
				document
					.querySelector(`.block-${blockIndex}-home#home-${id}`)
					.classList.toggle("item-selected")
				formik.resetForm()
				if (selectedHomes.find((item) => item.id === id)) {
					setHomeExpanded(false)
					setSelectedHomes([])
					resetFilledHomeValues(id, blockIndex)
				} else {
					setHomeExpanded(true)
					resetFilledHomeValues(
						selectedHomes[0]?.id,
						selectedHomes[0]?.blockIndex
					)
					setSelectedHomes([{ id: id, blockIndex: blockIndex }])
					setHomeValuesToForm(id, blockIndex)
				}
			}
		}
	}

	const toggleVisualViewHomeItem = (id, blockId) => {
		if (blocksQuery.data && blocksQuery.data.length > 0) {
			let foundBlockIndex = blocksQuery.data?.findIndex(
				(item) => item?.id === blockId
			)
			if (foundBlockIndex >= 0) {
				if (selectedHomes.find((item) => item.id === id)) {
					setHomeExpanded(false)
					setSelectedHomes([])
				} else {
					setHomeExpanded(true)
					setSelectedHomes([{ id: id, blockIndex: foundBlockIndex }])
				}
			} else {
				setHomeExpanded(false)
				setSelectedHomes([])
			}
		}
	}

	const handleWithPlanActive = (value) => {
		if (value) {
			if (selectedHomes.length > 1) {
				let firstSelectedHome = blocksQuery.data[
					selectedHomes[0].blockIndex
				]?.homes.filter((item) => item.id === selectedHomes[0].id)[0]
				selectedHomes.forEach(() => {
					formik.setFieldValue("plan_id", firstSelectedHome.plan_id || "")
				})
			}
		} else {
			formik.setFieldValue("plan_id", "")
		}
		setEditWithPlanActive(value)
	}

	const handleEditType = (value) => {
		selectedHomes.forEach((selectedHome) => {
			blocks[selectedHome.blockIndex]?.homes.splice(
				blocks[selectedHome.blockIndex]?.homes.findIndex(
					(item) => item.id === selectedHome.id
				),
				1,
				blocksQuery.data[selectedHome.blockIndex]?.homes.filter(
					(item) => item.id === selectedHome.id
				)[0]
			)
		})
		setBlocks([...blocks])
		resetSelection()
		setSelectedHomes([])
		formik.resetForm()
		setEditTypeMultiple(value)
		if (!value) {
			setHomeExpanded(false)
			setEditWithPlanActive(false)
		}
	}

	const handleResponse = (response) => {
		blocksQuery.refetch()
		formik.resetForm()
		resetSelection()
		setSelectedHomes([])
		setHomeExpanded(false)
	}

	const handleMultipleResponse = (multipleResponse) => {
		let result = multipleResponse.reduce((acc, curr) => {
			return acc && curr && curr.data && curr.data.status
		}, true)
		if (result) {
			sendNotification({
				msg: t("shaxmatka.alerts.success.homesUpdated"),
				variant: "success"
			})
			formik.resetForm()
			resetSelection()
			setSelectedHomes([])
			blocksQuery.refetch()
		}
		setIsMultipleSubmitting(false)
	}

	const setHomeValuesToForm = (homeId, blockIndex) => {
		let home = blocksQuery.data[blockIndex]?.homes.filter(
			(item) => item.id === homeId
		)[0]
		let newValues = {
			rooms: home.rooms || "",
			number: home.number || "",
			square: home.square || "",
			islive: home.islive || "",
			isrepaired: home.isrepaired || "",
			start: home.start || "",
			plan_id: home.plan_id || ""
		}
		formik.setValues(newValues)
	}

	const setMultipleHomeValuesToForm = (homeId, blockIndex) => {
		let home = blocksQuery.data[blockIndex]?.homes.filter(
			(item) => item.id === homeId
		)[0]
		let newValues = {
			rooms: home.rooms || "",
			square: home.square || "",
			islive: home.islive || "",
			isrepaired: home.isrepaired || "",
			start: home.start || ""
		}
		if (editWithPlanActive) {
			newValues.plan_id = home.plan_id || ""
		}
		formik.setValues(newValues)
	}

	const setFormDataToHome = (selectedH) => {
		let selectedHomeData =
			blocks[selectedH.blockIndex]?.homes[
				blocks[selectedH.blockIndex]?.homes.findIndex(
					(item) => item.id === selectedH.id
				)
			]
		let formikNewValues = {
			rooms: formik.values.rooms,
			square: formik.values.square,
			start: formik.values.start,
			isrepaired: formik.values.isrepaired,
			islive: formik.values.islive
		}
		if (editWithPlanActive) {
			formikNewValues.plan_id = formik.values.plan_id || ""
		}
		blocks[selectedH.blockIndex].homes[
			blocks[selectedH.blockIndex].homes.findIndex(
				(item) => item.id === selectedH.id
			)
		] = {
			...selectedHomeData,
			...formikNewValues
		}
	}

	const setSingleHomeDataFromForm = () => {
		if (selectedHomes.length > 0) {
			setFormDataToHome(selectedHomes[0])
			setBlocks([...blocks])
		}
	}

	const setMultipleHomeDataFromForm = () => {
		if (selectedHomes.length > 0) {
			selectedHomes.forEach((selectedHome) => {
				setFormDataToHome(selectedHome)
			})
			setBlocks([...blocks])
		}
	}

	const handleTabChange = (newValue) => {
		setTabValue(newValue)
		setResetFilter(true)
		setFilterExpanded(false)
		handleCloseHomeDetail()
	}

	const handleCloseHomeDetail = () => {
		resetSelection()
		setSelectedHomes([])
		setHomeExpanded(false)
	}

	const handleRefetchBlocks = () => {
		resetSelection()
		setSelectedHomes([])
		setHomeExpanded(false)
		blocksQuery.refetch()
	}

	useEffect(() => {
		let entries = Object.fromEntries(searchParams)
		if (Object.keys(entries).includes("objectId")) {
			objectQuery.refetch()
			blocksQuery.refetch()
			formik.resetForm()
			resetSelection()
			setSelectedHomes([])
			setFilterExpanded(false)
			setHomeExpanded(false)
		}
	}, [searchParams])

	useEffect(() => {
		setComponent(<ShaxmatkaTopPanel />)
	}, [i18n.language])

	useEffect(() => {
		if (!editTypeMultiple) {
			setSingleHomeDataFromForm()
		} else {
			setMultipleHomeDataFromForm()
		}
	}, [formik.values])

	useEffect(() => {
		if (shaxmatkaMode === "VIEW") {
			if (editTypeMultiple) {
				setHomeExpanded(false)
				selectedHomes.forEach((item) =>
					resetFilledHomeValues(item.id, item.blockIndex)
				)
				setSelectedHomes([])
				setEditTypeMultiple(false)
				resetSelection()
			}
		} else if (shaxmatkaMode === "EDIT") {
			setSelectedHomes([])
			setHomeExpanded(false)
			resetSelection()
		}
	}, [shaxmatkaMode])

	return (
		<div className="flex">
			<div
				className={`sheet-filter-wrapper${filterExpanded ? " is-full" : ""}`}
			>
				{filterExpanded && Object.keys(blocks).length > 0 && (
					<div className="sheet-filter-body">
						<div className="absolute top-0 right-0">
							<IconButton
								variant="onlyIcon"
								color="primary"
								onClick={() => setFilterExpanded((prev) => !prev)}
							>
								<i className="bi bi-x" />
							</IconButton>
						</div>
						<ShaxmatkaFilter
							blocks={blocks}
							filterExpanded={filterExpanded}
							resetFilter={resetFilter}
							setResetFilter={setResetFilter}
						/>
					</div>
				)}
			</div>
			<div
				className={`sheet-type-tabs${
					filterExpanded
						? homeExpanded
							? " is-mini-dual"
							: " is-mini"
						: homeExpanded
						? " is-mini"
						: ""
				}`}
			>
				<div className="flex mb-1 -mt-1 items-center">
					{!filterExpanded && tabValue !== 3 && (
						<Button
							variant={filterExpanded ? "filterContained" : "filterOutlined"}
							color="primary"
							startIcon={<i className="bi bi-filter" />}
							onClick={() => setFilterExpanded((prev) => !prev)}
							disabled={
								(blocksQuery.isLoading || blocksQuery.isFetching) &&
								(objectQuery.isLoading || objectQuery.isFetching)
							}
							size="small"
						>
							{t("common.button.filter")}
						</Button>
					)}
					<div className="sheet-top-action-wrapper">
						<ShaxmatkaStatuses
							isLoading={
								(blocksQuery.isLoading || blocksQuery.isFetching) &&
								(objectQuery.isLoading || objectQuery.isFetching)
							}
							blocks={blocks}
						/>
						<div className="flex items-center justify-end my-1">
							<ShaxmatkaModeSelect
								value={shaxmatkaMode}
								setValue={setShaxmatkaMode}
							/>
							<ShaxmatkaTypeMenu
								tabValue={tabValue}
								changeFn={handleTabChange}
							/>
							{tabValue === 0 && (
								<div className="ml-2">
									<Button
										variant="action"
										color="primary"
										ref={anchorSettingsRef}
										onClick={() => setSettingsMenu((prev) => !prev)}
										className="!w-9 !h-9"
									>
										<i className="bi bi-three-dots-vertical" />
									</Button>
									<Menu
										open={settingsMenu}
										anchorEl={anchorSettingsRef.current}
										disableScrollLock={true}
										onClose={() => setSettingsMenu(false)}
									>
										<MenuItem
											onClick={() => {
												setSettingsMenuValue("number")
												setSettingsMenu(false)
											}}
											className={`small-size${
												settingsMenuValue === "number" ? " is-active" : ""
											}`}
										>
											{t("common.filter.number")}
										</MenuItem>
										<MenuItem
											onClick={() => {
												setSettingsMenuValue("rooms")
												setSettingsMenu(false)
											}}
											className={`small-size${
												settingsMenuValue === "rooms" ? " is-active" : ""
											}`}
										>
											{t("common.filter.roomsNumber")}
										</MenuItem>
									</Menu>
								</div>
							)}
						</div>
					</div>
				</div>

				{tabValue === 0 && (
					<div className="sheet-wrapper type-2">
						<div className="sheet-base-area">
							<div className="sheet-grid">
								{(blocksQuery.isLoading || blocksQuery.isFetching) &&
								(objectQuery.isLoading || objectQuery.isFetching) ? (
									<div className="circular-progress-box min-h-[500px] h-full w-full">
										<CircularProgress size={50} />
									</div>
								) : (
									blocksQuery.data &&
									blocksQuery.data.length > 0 &&
									blocks &&
									blocks.map((block, index) => (
										<div
											className="sheet-column"
											key={`block-${block?.id}-columns`}
											id={`block-${block?.id}-columns`}
										>
											<Shaxmatka2BlockItem
												blockItem={block}
												blockIndex={index}
												toggleSelectionItem={toggleSelectionItem}
												settingsMenuValue={settingsMenuValue}
											/>
										</div>
									))
								)}
							</div>
						</div>
					</div>
				)}
				{tabValue === 1 && (
					<div className="sheet-wrapper">
						<div className="sheet-base-area">
							<div className="sheet-grid">
								{(blocksQuery.isLoading || blocksQuery.isFetching) &&
								(objectQuery.isLoading || objectQuery.isFetching) ? (
									<div className="circular-progress-box min-h-[500px] h-full w-full">
										<CircularProgress size={50} />
									</div>
								) : (
									blocksQuery.data &&
									blocksQuery.data.length > 0 &&
									blocks && (
										<Fragment>
											{blocks.map((block, index) => (
												<div
													className="sheet-column"
													key={`block-${block?.id}-columns`}
													id={`block-${block?.id}-columns`}
												>
													<ShaxmatkaBlockItem
														blockItem={block}
														blockIndex={index}
														toggleSelectionItem={toggleSelectionItem}
													/>
												</div>
											))}
										</Fragment>
									)
								)}
							</div>
						</div>
					</div>
				)}
				{tabValue === 2 && (
					<div className="pb-4 pt-2">
						<ShaxmatkaBlocksTable
							isLoading={
								(blocksQuery.isLoading || blocksQuery.isFetching) &&
								(objectQuery.isLoading || objectQuery.isFetching)
							}
							isError={blocksQuery.isError || objectQuery.isError}
							blocks={blocks}
							objectData={objectQuery?.data}
							toggleSelectionItem={toggleSelectionItem}
						/>
					</div>
				)}
				{tabValue === 3 && (
					<ShaxmatkaVisualView toggleHomeItem={toggleVisualViewHomeItem} />
				)}
			</div>
			<div className={`sheet-actions-area${homeExpanded ? " is-full" : ""}`}>
				{homeExpanded &&
					(shaxmatkaMode === "EDIT" ? (
						<div className="sheet-actions-body">
							<Grid
								container
								spacing={2}
								rowSpacing={1}
								columns={{ xs: 12, sm: 12 }}
							>
								<Grid item={true} sm={12}>
									<SimpleCheckbox
										delay={0}
										duration={0}
										label={t("shaxmatka.changeMultiple")}
										value={editTypeMultiple}
										setValue={handleEditType}
									/>
								</Grid>
							</Grid>
							<div className="sheet-form-action">
								<form onSubmit={formik.handleSubmit}>
									<Grid
										container
										spacing={2}
										rowSpacing={1}
										columns={{ xs: 12, sm: 12 }}
									>
										{!editTypeMultiple && (
											<Grid
												item={true}
												sm={12}
												sx={{ marginBottom: "-0.5rem" }}
											>
												<FormTextField
													delay={0}
													label={t("common.fields.homeNumber")}
													fieldName="number"
													formik={formik}
												/>
											</Grid>
										)}

										<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
											<FormTextField
												delay={0}
												label={t("common.fields.rooms")}
												fieldName="rooms"
												formik={formik}
											/>
										</Grid>

										<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
											<FormNumberField
												delay={0}
												label={t("common.fields.homeAreAll")}
												fieldName="square"
												formik={formik}
												decimalScale={2}
											/>
										</Grid>

										<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
											<FormCurrencyField
												delay={0}
												fieldName="start"
												formik={formik}
												label={t("common.fields.startPricePercentage")}
												decimalScale={1}
											/>
											{formik.values.isvalute === "1" &&
												!isNaN(formik.values.start) && (
													<CurrencySubContent
														value={formik.values.start || "0"}
													/>
												)}
										</Grid>

										<Grid item={true} sm={12}>
											<FormRadioGroupField
												delay={0}
												fieldName="islive"
												formik={formik}
												label={t("common.fields.residentType")}
												options={residentTypeSelectOptions}
											/>
										</Grid>

										<Grid
											item={true}
											sm={12}
											sx={{ marginTop: "-0.5rem", marginBottom: "-0.5rem" }}
										>
											<FormRadioGroupField
												delay={0}
												fieldName="isrepaired"
												formik={formik}
												label={t("common.fields.repairType")}
												options={repairTypeSelectOptions}
											/>
										</Grid>

										{editTypeMultiple && (
											<Grid item={true} sm={12}>
												<SimpleCheckbox
													delay={0}
													duration={0}
													label={t("shaxmatka.withPlan")}
													value={editWithPlanActive}
													setValue={handleWithPlanActive}
												/>
											</Grid>
										)}

										{!editTypeMultiple && (
											<Grid item={true} sm={12}>
												<FormLayoutField
													delay={0}
													label={t("common.fields.plan")}
													fieldName="plan_id"
													formik={formik}
													path={`/admin/plan/index?block_id=${selectedHomeBlockId}`}
												/>
											</Grid>
										)}

										{editWithPlanActive && (
											<Grid item={true} sm={12}>
												<FormLayoutField
													delay={0}
													label={t("common.fields.plan")}
													fieldName="plan_id"
													formik={formik}
													path={`/admin/plan/index?block_id=${selectedHomeBlockId}`}
												/>
											</Grid>
										)}

										<Grid item={true} sm={12} xs={12}>
											<FormActionButtons
												delay={0}
												isSubmitting={isSubmitting || isMultipleSubmitting}
												onlySave
												className="mt-0"
											/>
										</Grid>
									</Grid>
								</form>
							</div>
						</div>
					) : shaxmatkaMode === "PRICE_EDIT" ? (
						<ShaxmatkaPriceEditForm
							blocks={blocks}
							objectId={searchParams.get("objectId")}
							setHomeExpanded={setHomeExpanded}
						/>
					) : (
						<div className="sheet-actions-body">
							<div className="absolute top-0 right-0">
								<IconButton
									variant="onlyIcon"
									color="primary"
									onClick={() => handleCloseHomeDetail()}
								>
									<i className="bi bi-x" />
								</IconButton>
							</div>
							<ShaxmatkaHomeDetail
								selectedHome={selectedHomes}
								blocks={blocksQuery.data}
								object={objectQuery.data}
								refetchFn={handleRefetchBlocks}
							/>
						</div>
					))}
			</div>
		</div>
	)
}

const ShaxmatkaPriceEditForm = ({
	blocks = [],
	objectId = "",
	setHomeExpanded = () => {}
}) => {
	const { submit, isSubmitting } = useFormSubmit()
	const { t } = useTranslation()
	const { currencyData } = useCurrency()
	const [selectedBlocks, setSelectedBlocks] = useState(
		blocks.map((item) => item?.id)
	)
	const [selectedStages, setSelectedStages] = useState([])
	const [selectedSquares, setSelectedSquares] = useState([])

	const stages = useMemo(() => {
		let result = getShaxmatkaFieldDataByBlocks(blocks, "stage")
		setSelectedStages([...result])
		return result
	}, [blocks])

	const squares = useMemo(() => {
		let result = getShaxmatkaFieldDataByBlocks(blocks, "square")
		setSelectedSquares([...result])
		return result
	}, [blocks])

	const formik = useFormik({
		initialValues: {
			isvalute: "0",
			repaired: "",
			norepaired: "",
			deal_price: null
		},
		validationSchema: priceEditValidationSchema,
		onSubmit: async (values) => {
			let newValues = {
				repaired: values.repaired,
				norepaired: values.norepaired,
				deal_price: values.deal_price,
				isvalute: values.isvalute,
				squares: selectedSquares,
				stages: selectedStages,
				blocks: selectedBlocks
			}
			submit(
				{ type: "post", contentType: "simple" },
				newValues,
				`/admin/home/updateprice/${objectId}`,
				"Belgilangan parametrli xonadonlar",
				null,
				true,
				handleResponse
			)
		}
	})

	const handleResponse = () => {
		setHomeExpanded(false)
	}

	const handleCurrencyChange = (value) => {
		formik.setFieldValue("isvalute", value, true)
		if (currencyData && currencyData.sum) {
			if (value === "1") {
				formik.setFieldValue(
					"repaired",
					parseFloat((formik.values.repaired || "0") / currencyData.sum),
					true
				)
				formik.setFieldValue(
					"norepaired",
					parseFloat((formik.values.norepaired || "0") / currencyData.sum),
					true
				)
				formik.setFieldValue(
					"deal_price",
					parseFloat((formik.values.deal_price || "0") / currencyData.sum),
					true
				)
			} else if (value === "0") {
				formik.setFieldValue(
					"repaired",
					parseFloat((formik.values.repaired || "0") * currencyData.sum),
					true
				)
				formik.setFieldValue(
					"norepaired",
					parseFloat((formik.values.norepaired || "0") * currencyData.sum),
					true
				)
				formik.setFieldValue(
					"deal_price",
					parseFloat((formik.values.deal_price || "0") * currencyData.sum),
					true
				)
			}
		}
	}

	const isBlockSelected = (blockId) => selectedBlocks.includes(blockId)

	const handleBlockChange = (blockId) => {
		if (isBlockSelected(blockId)) {
			selectedBlocks.splice(selectedBlocks.indexOf(blockId), 1)
		} else {
			selectedBlocks.push(blockId)
		}
		setSelectedBlocks([...selectedBlocks])
	}

	const isStageSelected = (blockId) => selectedStages.includes(blockId)

	const handleStageChange = (stage) => {
		if (isStageSelected(stage)) {
			selectedStages.splice(selectedStages.indexOf(stage), 1)
		} else {
			selectedStages.push(stage)
		}
		setSelectedStages([...selectedStages])
	}

	const handleSquaresChange = (event) => {
		setSelectedSquares(event?.target?.value || [])
	}

	return (
		<div className="sheet-actions-body">
			<form onSubmit={formik.handleSubmit}>
				<Grid container spacing={2} rowSpacing={1} columns={{ xs: 12, sm: 12 }}>
					<Grid item={true} sm={12}>
						{t("common.fields.blocks")}
						{blocks && blocks.length > 0 && (
							<div className="blocks-name-wrapper price-form-item-wrapper">
								{blocks.map((item) => (
									<Button
										className={`block-item field-item${
											isBlockSelected(item.id) ? " item-selected" : ""
										}`}
										color="primary"
										variant={
											isBlockSelected(item.id) ? "contained" : "outlined"
										}
										key={`price-edit-form-block-${item.id}`}
										onClick={() => handleBlockChange(item.id)}
									>
										{item?.name}
									</Button>
								))}
							</div>
						)}
					</Grid>
					<Grid item={true} sm={12}>
						<div className="price-form-item-divider"></div>
					</Grid>
					<Grid item={true} sm={12}>
						{t("common.fields.stages")}
						{stages && stages.length > 0 && (
							<div className="stages-name-wrapper price-form-item-wrapper">
								{stages.map((item) => (
									<Button
										className={`stage-item field-item${
											isStageSelected(item) ? " item-selected" : ""
										}`}
										color="primary"
										variant={isStageSelected(item) ? "contained" : "outlined"}
										key={`price-edit-form-stage-${item}`}
										onClick={() => handleStageChange(item)}
									>
										{item}
									</Button>
								))}
							</div>
						)}
					</Grid>
					<Grid item={true} sm={12}>
						<div className="price-form-item-divider"></div>
					</Grid>
					<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
						<FormControl fullWidth color="formColor">
							<InputLabel id="squares-select-label">
								{t("common.fields.squares")}
							</InputLabel>
							<Select
								labelId="squares-select-label"
								id="squares-select-multiple-chip"
								multiple
								value={selectedSquares}
								onChange={handleSquaresChange}
								input={
									<OutlinedInput
										id="squares-select-multiple-chip"
										variant="filterField"
										label={t("common.fields.squares")}
									/>
								}
								renderValue={(selected) => (
									<Box
										sx={{
											display: "flex",
											flexWrap: "wrap",
											gap: 0.5
										}}
									>
										{selected.map((value) => (
											<Chip
												key={`square-${value}`}
												label={value}
												variant="tableBadge"
												color="primary"
												onMouseDown={(event) => {
													event.stopPropagation()
												}}
												size="small"
											/>
										))}
									</Box>
								)}
								color="formColor"
								variant="outlined"
								MenuProps={{
									id: "squares-select-menu",
									PaperProps: {
										style: {
											maxHeight: 200
										}
									},
									disableScrollLock: true
								}}
								endAdornment={
									<InputAdornment
										position="end"
										className="custom-endAdornment"
									>
										<IconButton
											onClick={() => setSelectedSquares([])}
											size="small"
											variant="onlyIcon"
											sx={{
												display: selectedSquares.length > 0 ? "" : "none"
											}}
											className="select-box-end-adornment-button"
										>
											<i className="bi bi-x end-adornment-close-button" />
										</IconButton>
									</InputAdornment>
								}
							>
								{squares && squares.length > 0 ? (
									squares.map((item, index) => (
										<MenuItem
											value={item}
											key={`square-${index + 1}`}
											className="small-size"
										>
											{item}
										</MenuItem>
									))
								) : (
									<div>
										<span className="no-data-found-wrapper select-box">
											<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
											{t("common.global.noDataFound")}
										</span>
									</div>
								)}
							</Select>
						</FormControl>
					</Grid>
					<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
						<FormSimpleSelectField
							delay={0}
							fieldName="isvalute"
							formik={formik}
							label={t("common.fields.currency")}
							options={currencyTypeSelectOptions}
							itemValue="code"
							itemLabel="label"
							changeFn={handleCurrencyChange}
						/>
					</Grid>

					<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
						<FormCurrencyField
							delay={0}
							fieldName="repaired"
							formik={formik}
							label={
								<span>
									<Trans i18nKey="common.fields.repairedPrice">
										Ta'mirlangan narxi(1 m<sup>2</sup>)
									</Trans>
								</span>
							}
							decimalScale={1}
						/>
						{formik.values.isvalute === "1" &&
							!isNaN(formik.values.repaired) && (
								<CurrencySubContent value={formik.values.repaired || "0"} />
							)}
					</Grid>

					<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
						<FormCurrencyField
							delay={0}
							fieldName="norepaired"
							formik={formik}
							label={
								<span>
									<Trans i18nKey="common.fields.noRepairedPrice">
										Ta'mirlanmagan narxi(1 m<sup>2</sup>)
									</Trans>
								</span>
							}
							decimalScale={1}
						/>
						{formik.values.isvalute === "1" &&
							!isNaN(formik.values.norepaired) && (
								<CurrencySubContent value={formik.values.norepaired || "0"} />
							)}
					</Grid>

					<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
						<FormCurrencyField
							delay={0}
							fieldName="deal_price"
							formik={formik}
							label={
								<span>
									<Trans i18nKey="common.fields.dealPrice">
										Kelishilgan holda Ta'mirlangan narxi (1 m<sup>2</sup>)
									</Trans>
								</span>
							}
							decimalScale={1}
						/>
						{formik.values.isvalute === "1" &&
							!isNaN(formik.values.deal_price) && (
								<CurrencySubContent value={formik.values.deal_price || "0"} />
							)}
					</Grid>

					<Grid item={true} sm={12} xs={12}>
						<FormActionButtons
							delay={0}
							isSubmitting={isSubmitting}
							onlySave
							className="mt-0"
						/>
					</Grid>
				</Grid>
			</form>
		</div>
	)
}

export default ShaxmatkaBlock
