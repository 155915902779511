import { Button } from "@mui/material"
import CRMBlocksMenu from "./CRMBlocksMenu"
import { useNavigate } from "react-router-dom"
import CRMOperatorSync from "./CRMOperatorSync"
import CRMLeadSearch from "./card/CRMLeadSearch"

const CRMTopPanel = ({
	selectedBlock = {},
	setSelectedBlock = () => {},
	addBlock = false,
	addLead = false
}) => {
	const navigate = useNavigate()

	return (
		<div className="crm-top-panel-wrapper">
			<CRMBlocksMenu
				selectedBlock={selectedBlock}
				setSelectedBlock={setSelectedBlock}
				addAllowed={addBlock}
			/>
			<div className="flex ml-auto items-center">
				<div className="mr-3">
					<CRMLeadSearch />
				</div>
				<div className="mr-3">
					<Button
						variant="contained"
						color="success"
						onClick={() => {
							navigate("/admin/crm/lead/add")
						}}
						className="h-10"
					>
						Yangi yaratish
					</Button>
				</div>
				<div>
					<CRMOperatorSync />
				</div>
			</div>
		</div>
	)
}
export default CRMTopPanel
