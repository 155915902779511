import { Button, CircularProgress, Grid } from "@mui/material"
import axios from "api/axios"
import LanguageMenu from "components/LanguageMenu"
import FormPatternField from "components/ui/form/FormPatternField"
import FormTextField from "components/ui/form/FormTextField"
import { useFormik } from "formik"
import { motion } from "framer-motion"
import useNotification from "hooks/useNotification"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { fadeUp } from "utils/motion"
import * as yup from "yup"

const initialValidationSchema = yup.object({
	name: yup.string().required("customerPreferences.validation.name"),
	phone: yup
		.string()
		.length(13, "customerPreferences.validation.phoneValid")
		.required("customerPreferences.validation.phone")
})

const CustomerPreferences = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const sendNotification = useNotification()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [keyValue, setKeyValue] = useState(null)

	const formik = useFormik({
		initialValues: {
			name: "",
			phone: ""
		},
		validationSchema: initialValidationSchema,
		onSubmit: async (values) => {
			try {
				setIsSubmitting(true)
				let newValues = {
					...values
				}
				if (keyValue) {
					newValues.key = keyValue
				} else {
					delete newValues.key
				}

				const response = await axios.post("/metalead", newValues, {
					headers: { "Content-Type": "application/json" }
				})
				if (response.data && response.data.status) {
					sendNotification({
						msg: t("customerPreferences.alerts.success"),
						variant: "success"
					})
					formik.resetForm()
					navigate(-1)
				}
				setIsSubmitting(false)
			} catch (error) {
				console.error(error)
				setIsSubmitting(false)
			}
		}
	})

	useEffect(() => {
		if (searchParams.get("k")) {
			setKeyValue(searchParams.get("k"))
		} else {
			setKeyValue(null)
		}
	}, [searchParams])

	return (
		<div className="customer-preferences-wrapper">
			<div className="absolute top-5 right-5">
				<LanguageMenu animate={true} />
			</div>
			<div className="form-wrapper">
				<form onSubmit={formik.handleSubmit}>
					<Grid
						container
						spacing={1}
						rowSpacing={0.1}
						columns={{ xs: 12, sm: 12, lg: 12 }}
					>
						<Grid item={true} sm={12} xs={12}>
							<FormTextField
								delay={0}
								label={t("customerPreferences.fields.name")}
								fieldName="name"
								formik={formik}
								disabled={isSubmitting}
							/>
						</Grid>
						<Grid item={true} sm={12} xs={12}>
							<FormPatternField
								delay={0.1}
								label={t("customerPreferences.fields.phone")}
								fieldName="phone"
								formik={formik}
								format="+998#########"
								disabled={isSubmitting}
							/>
						</Grid>
						<Grid item={true} sm={12} xs={12}>
							<div className="text-center mt-3">
								<Button
									color="success"
									variant="contained"
									type="submit"
									component={motion.button}
									variants={fadeUp(30, "tween", 0.2, 0.5)}
									initial="hidden"
									animate="show"
									viewport={{ once: true, amount: 0.25 }}
									disabled={isSubmitting}
								>
									{isSubmitting && (
										<CircularProgress
											size={15}
											color="inherit"
											className="mr-1"
										/>
									)}
									{t("common.button.send")}
								</Button>
							</div>
						</Grid>
					</Grid>
				</form>
			</div>
		</div>
	)
}
export default CustomerPreferences
